import React from "react"

import Layout from "../components/layout"

const IndexPage = () => (
    <Layout>
        <div className="bg-white">
            <div className="max-w-xl mx-auto lg:mb-24 px-4 sm:px-6 lg:max-w-screen-xl lg:px-8">
                <div className='flex text-center align-center mt-12 text-5xl font-bold tracking-wide'>
                    Software Estimation
                </div>

                <div className='flex justify-center align-center mt-24 text-xl tracking-wide'>
                    Work in Progress
                </div>
            </div>
        </div>
    </Layout>
);

export default IndexPage